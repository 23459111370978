import React from "react";
import { motion } from "framer-motion";

const Preloader = () => {
  const loaderStyle = {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "100vh",
    backgroundColor: "#ffffff",
  };

  const imageStyle = {
    width: "100px",
    height: "100px",
  };

  return (
    <div style={loaderStyle}>
      <motion.img
        src="https://niyaliving.in/images/favicon.png"
        alt="Loading..."
        style={imageStyle}
        animate={{ y: [0, -20, 0] }} // Bounce effect
        transition={{ duration: 1.5, repeat: Infinity, ease: "easeInOut" }}
      />
    </div>
  );
};

export default Preloader;
