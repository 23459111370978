import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import api from "../../api";
import { Helmet } from "react-helmet";

const BlogSection = () => {
  const [articles, setArticles] = useState([]);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await api.get("/blogs");
        setArticles(response.data.data);
      } catch (err) {
        setError(err);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  if (loading) {
    return (
      <section className="news-section">
        <div className="container-fluid">
          <div className="row">
            {Array.from({ length: 6 }).map((_, index) => (
              <div
                className="news-block col-lg-4 col-sm-6 wow fadeInUp"
                key={index}
              >
                <div className="inner-box">
                  <div className="image-box">
                    <figure className="image">
                      <Skeleton height={200} />
                    </figure>
                  </div>
                  <div className="content-box">
                    <h4 className="title">
                      <Skeleton height={30} width="80%" />
                    </h4>
                    <p className="text">
                      <Skeleton height={20} count={2} />
                    </p>
                    <Link to="#" className="read-more">
                      <Skeleton height={30} width={100} />
                    </Link>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>
    );
  }

  if (error) {
    return <div>Error: {error.message}</div>;
  }

  return (
    <>
      <Helmet>
        <title>Blogs by Niya Living | Furniture & Home Decor Tips</title>
        <meta
          name="title"
          content="Blogs by Niya Living | Furniture & Home Decor Tips"
        />
        <meta
          name="description"
          content="Read the latest blogs from Niya Living. Get expert tips on furniture, home decor, and design ideas to enhance your living space with style and comfort."
        />
      </Helmet>

      <section
        className="page-title"
        style={{ backgroundImage: "url(images/background/37.png)" }}
      >
        <div className="container-fluid">
          <div className="title-outer text-center">
            <h1 className="title">Blogs</h1>
            <ul className="page-breadcrumb">
              <li>
                <Link to="/">Home</Link>
              </li>
              <li>Blogs</li>
            </ul>
          </div>
        </div>
      </section>

      <section className="news-section">
        <div className="container">
          <div className="row">
            {articles.map((article) => (
              <div
                className="news-block col-lg-4 col-sm-6 wow fadeInUp"
                key={article.id}
              >
                <div className="inner-box">
                  <div className="image-box">
                    <figure className="image">
                      <Link to={`/blog/${article.slug}`}>
                        <img
                          src={article.banner}
                          alt={`Blog Post ${article.id}`}
                        />
                      </Link>
                    </figure>
                  </div>
                  <div className="content-box">
                    <h4 className="title">
                      <Link to={`/blog/${article.slug}`}>{article.title}</Link>
                    </h4>
                    <p className="text line-clamp mt-2">
                      {article.description.replace(/<\/?[^>]+(>|$)/g, "")}
                    </p>

                    <style jsx>{`
                      .line-clamp {
                        display: -webkit-box;
                        -webkit-line-clamp: 3;
                        -webkit-box-orient: vertical;
                        overflow: hidden;
                      }
                    `}</style>
                    <Link to={`/blog/${article.slug}`} className="read-more">
                      READ MORE
                    </Link>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>
    </>
  );
};

export default BlogSection;
