import React, { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import api from "../../../api";

const ProductFilter = ({ onFilterChange, onResetFilters }) => {
    const [filters, setFilters] = useState({});
    const [searchParams, setSearchParams] = useSearchParams();

    const initialFilters = {
        Material: searchParams.get("Material") || "",
        Size: searchParams.get("Size") || "",
        Price: searchParams.get("Price") || "",
        Assembly: searchParams.get("Assembly") || "",
        Availability: searchParams.get("Availability") || "",
        Advanced: searchParams.get("Advanced") || "",
    };

    const [selectedFilters, setSelectedFilters] = useState(initialFilters);

    useEffect(() => {
        const fetchFilters = async () => {
            try {
                const response = await api.get("/filter/attributes");
                if (response.status) {
                    setFilters(response.data.data);
                }
            } catch (error) {
                console.error("Error fetching filters:", error);
            }
        };

        fetchFilters();
    }, []);

    const handleFilterChange = (key, value) => {
        const updatedFilters = { ...selectedFilters, [key]: value };
        setSelectedFilters(updatedFilters);

        const newSearchParams = { ...Object.fromEntries(searchParams.entries()), [key]: value };
        // setSearchParams(newSearchParams);

        onFilterChange(updatedFilters);
    };

    const resetFilters = () => {
        const reset = {
            Material: "",
            Size: "",
            Price: "",
            Assembly: "",
            Availability: "",
            Advanced: "",
        };
        setSelectedFilters(reset);
        setSearchParams({});
        onResetFilters(reset);
    };

    return (
        <div className="container-fluid">
            <div className="row">
                <div className="products-filter">
                    <h6>Filter by:</h6>

                    {/* {filters.Material && (
                        <select
                            className="form-select"
                            onChange={(e) => handleFilterChange("Material", e.target.value)}
                            value={selectedFilters.Material}
                        >
                            <option value="">Material</option>
                            {filters.Material.map((material) => (
                                <option key={material.id} value={material.value}>
                                    {material.value}
                                </option>
                            ))}
                        </select>
                    )} */}

                    {/* {filters.Size && (
                        <select
                            className="form-select"
                            onChange={(e) => handleFilterChange("Size", e.target.value)}
                            value={selectedFilters.Size}
                        >
                            <option value="">Size</option>
                            {filters.Size.map((size) => (
                                <option key={size.id} value={size.value}>
                                    {size.value}
                                </option>
                            ))}
                        </select>
                    )} */}

                    <select
                        className="form-select"
                        onChange={(e) => handleFilterChange("Price", e.target.value)}
                        value={selectedFilters.Price}
                    >
                        <option value="">Price</option>
                        <option value="under_10000">Under ₹10,000</option>
                        <option value="10000_20000">₹10,000 - ₹20,000</option>
                        <option value="20000_30000">₹20,000 - ₹30,000</option>
                        <option value=">above_30000">Above ₹30,000</option>
                    </select>

                    {/* {filters.Assembly && (
                        <select
                            className="form-select"
                            onChange={(e) => handleFilterChange("Assembly", e.target.value)}
                            value={selectedFilters.Assembly}
                        >
                            <option value="">Assembly</option>
                            {filters.Assembly.map((assembly) => (
                                <option key={assembly.id} value={assembly.value}>
                                    {assembly.value}
                                </option>
                            ))}
                        </select>
                    )} */}

                    <select
                        className="form-select"
                        onChange={(e) => handleFilterChange("Availability", e.target.value)}
                        value={selectedFilters.Availability}
                    >
                        <option value="">Availability</option>
                        <option value="In Stock">In Stock</option>
                        <option value="Made to Order">Made to Order</option>
                        <option value="Out of Stock">Out of Stock</option>
                    </select>

                    <select
                        className="form-select"
                        onChange={(e) => handleFilterChange("Advanced", e.target.value)}
                        value={selectedFilters.Advanced}
                    >
                        <option value="">Advanced Filters</option>
                        <option value="New Article">New Article</option>
                        <option value="Popular">Popular</option>
                    </select>

                    <button className="btn filterBtn" onClick={resetFilters}>
                        Reset Filters
                    </button>
                </div>
            </div>
        </div>
    );
};

export default ProductFilter;