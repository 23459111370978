import React, { useState } from 'react';
import { Helmet } from 'react-helmet';

const Faq = () => {
    const [activeIndex, setActiveIndex] = useState(0);

    const toggleAccordion = (index) => {
        setActiveIndex(activeIndex === index ? null : index);
    };

    const faqData = [
        { question: "What is Niya Living?", answer: "Niya Living is a furniture and home decor brand that combines creativity, craftsmanship, and innovation to design unique home pieces." },
        { question: "What type of furniture does Niya Living offer?", answer: "Niya Living offers a variety of home furniture and decor items, all crafted with quality materials and designs." },
        { question: "How can I place an order?", answer: "Orders can be placed directly through the website by selecting your preferred items and proceeding to checkout." },
        { question: "What payment methods are accepted?", answer: "Most major payment options, including credit/debit cards and UPI, are likely accepted." },
        { question: "What types of wood are used in Niya Living furniture?", answer: "Niya Living uses high-quality wood such as Acacia, sheesham, mango, and engineered wood for durability and aesthetics." },
        { question: "Is solid wood furniture better than engineered wood?", answer: "Solid wood furniture is more durable and long-lasting, while engineered wood is cost-effective and resistant to moisture." },
        { question: "How should I clean my wooden furniture?", answer: "Use a soft, damp cloth for dusting and avoid harsh chemicals or excessive water." },
        { question: "Is wooden furniture eco-friendly?", answer: "Sustainable wood sources and recycled materials make wooden furniture an eco-friendly choice." },
        { question: "Does Niya Living provide a warranty on wooden furniture?", answer: "Warranty policies depend on the product. Check with Niya Living’s customer support for specific details." },
        { question: "Are wooden beds durable?", answer: "Yes, solid wood beds last for decades and provide strong support compared to metal or engineered wood beds." },
    ];

    const faqSchema = {
        "@context": "https://schema.org",
        "@type": "FAQPage",
        "mainEntity": faqData.map((faq) => ({
            "@type": "Question",
            "name": faq.question,
            "acceptedAnswer": {
                "@type": "Answer",
                "text": faq.answer
            }
        }))
    };

    return (
        <>
            <Helmet>
                <title>FAQs | Niya Living - Get Your Questions Answered</title>
                <meta name="title" content="FAQs | Niya Living - Get Your Questions Answered" />
                <meta name="description" content="Visit Niya Living's FAQs page for answers to common questions about our products, services, and policies. Find helpful information to guide your shopping experience." />
                <script type="application/ld+json">{JSON.stringify(faqSchema)}</script>
            </Helmet>

            <section
                className="about-page-title"
                style={{ backgroundImage: `url('/images/about-us.png')` }}
            >
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-lg-12">
                            <h1 className="title">FAQ'S</h1>
                        </div>
                    </div>
                </div>
            </section>

            <section className="faqs-section-home1 my-5 pb-md-20">
                <div className="auto-container">
                    <div className="row">
                        <div className="faq-column col-lg-12">
                            <div className="inner-column">
                                <ul className="accordion-box wow fadeInLeft">
                                    {faqData.map((faq, index) => (
                                        <li
                                            key={index}
                                            className={`accordion block ${activeIndex === index ? 'active-block' : ''}`}
                                        >
                                            <div
                                                className={`acc-btn ${activeIndex === index ? 'active' : ''}`}
                                                onClick={() => toggleAccordion(index)}
                                            >
                                                {faq.question}
                                                <div className="arrow far fa-arrow-right"></div>
                                            </div>
                                            <div className={`acc-content ${activeIndex === index ? 'current' : ''}`}>
                                                <div className="content">
                                                    <div className="text">{faq.answer}</div>
                                                </div>
                                            </div>
                                        </li>
                                    ))}
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
};

export default Faq;
