import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import PageTitle from "./modules/PageTitle";
import ProductFilter from "./modules/ProductFilter";
import { Link, useParams } from "react-router-dom";
import api from '../../api';
import { toast, ToastContainer } from 'react-toastify';
import Preloader from "../../components/Preloader";

const ProductCategories = ({ handleCart, handleWishlist }) => {
    const { slug } = useParams();
    const [products, setProducts] = useState([]);
    const [category, setCategory] = useState(null);
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(true);
    const [currentPage, setCurrentPage] = useState(1);
    const [lastPage, setLastPage] = useState(1);
    const [loginData, setLoginData] = useState(null);
    const [selectedFilters, setSelectedFilters] = useState({});

    useEffect(() => {
        const userData = sessionStorage.getItem("user_data");
        if (userData) {
            try {
                const parsedData = JSON.parse(userData);
                setLoginData(parsedData);
            } catch {
                setLoginData(null);
            }
        } else {
            setLoginData(null);
        }
    }, []);

    const fetchCategoryAndProducts = async (filters = {}) => {
        setLoading(true);
        try {
            const queryParams = new URLSearchParams({
                page: currentPage,
                availability: filters.Availability || "",
                price: filters.Price || "",
                advanced_filters: filters.Advanced || "",
            });

            if (filters.Material) {
                queryParams.append("selected_attribute_values[]", `Material:${filters.Material}`);
            }
            if (filters.Size) {
                queryParams.append("selected_attribute_values[]", `Size:${filters.Size}`);
            }
            if (filters.Assembly) {
                queryParams.append("selected_attribute_values[]", `Assembly:${filters.Assembly}`);
            }

            const response = await api.get(`/products/category/${slug}?${queryParams.toString()}`);
            if (response.data && response.data.meta) {
                setProducts(response.data.data || []);
                setLastPage(response.data.meta.last_page || 1);
            } else {
                setError(new Error("Invalid API response format"));
            }

            const categoriesResponse = await api.get("/categories");
            const matchedCategory = categoriesResponse.data.data.find((cat) => cat.slug === slug);
            if (matchedCategory) {
                setCategory(matchedCategory);
            } else {
                setCategory(null);
            }
        } catch (err) {
            setError(err);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        if (slug) {
            fetchCategoryAndProducts(selectedFilters);
        }
    }, [slug, currentPage, selectedFilters]);

    const handleFilterChange = (filters) => {
        setSelectedFilters(filters);
        setCurrentPage(1);
    };

    const handlePageChange = (page) => {
        if (page > 0 && page <= lastPage) {
            setCurrentPage(page);
        }
    };

    if (loading) return <Preloader />;
    if (error) return <div>Error: {error.message}</div>;

    return (
        <>
            <Helmet>
                <title>{category.meta_title}</title>
                <meta name="title" content={category.meta_title} />
                <meta name="description" content={category.meta_description} />
            </Helmet>
            <PageTitle category={category} />
            <ProductFilter onFilterChange={handleFilterChange} onResetFilters={() => handleFilterChange({})} />

            <section className="products-section">
                <div className="container-fluid">
                    <div className="row">
                        {products.map((product) => (
                            <div className="product-block col-lg-4 col-md-6 col-sm-6 col-12" key={product.id}>
                                <div className="inner-box">
                                    <div className="image">
                                        <Link to={`/products/${product.slug}`}>
                                            <img src={product.thumbnail_image} alt={product.name} />
                                        </Link>
                                    </div>

                                    {product.discount !== 0 && (
                                        <div className="product-success">
                                            {product.discount}% Off
                                        </div>
                                    )}
                                    <div className="product-made">
                                        {product.stock_quantity === 0 ? (
                                            <span className="text-danger">Out Of Stock</span>
                                        ) : (
                                            <span >MADE TO ORDER</span>
                                        )}
                                    </div>
                                    <div className="content-box">
                                        <Link to={`/products/${product.slug}`} className="buy-btn">
                                            BUY NOW
                                        </Link>
                                        <Link
                                            to="#"
                                            onClick={() => handleCart(product.id, '', loginData?.id, 1)}
                                            className="cart-btn"
                                        >
                                            ADD TO CART
                                        </Link>
                                    </div>
                                    {product.discount !== 0 && (
                                        <div className="product-success">
                                            {product.discount}% Off
                                        </div>
                                    )}
                                    <div className="product-made">
                                        {product.stock_quantity === 0 ? (
                                            <span className="text-danger">Out Of Stock</span>
                                        ) : (
                                            <span >MADE TO ORDER</span>
                                        )}
                                    </div>
                                    <div className="content">
                                        <h4>
                                            <Link to={`/products/${product.slug}`}>
                                                {product.name}
                                            </Link>
                                        </h4>
                                        <span className="price">
                                            {product.stocked_price !== product.main_price && (
                                                <del>{product.stocked_price}</del>
                                            )}
                                            &nbsp;{product.main_price}
                                        </span>
                                    </div>
                                    <div className="icon-box">
                                        {loginData ? (
                                            <Link
                                                to="#"
                                                onClick={() => handleWishlist(product.slug)}
                                                className="ui-btn like-btn"
                                                aria-label="Add to Wishlist"
                                            >
                                                <i className="fa fa-heart"></i>
                                            </Link>
                                        ) : (
                                            <Link
                                                to="/login"
                                                className="ui-btn add-to-cart"
                                                aria-label="Login to Add to Cart"
                                            >
                                                <i className="fa fa-heart"></i>
                                            </Link>
                                        )}
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>

                    {/* Pagination controls */}
                    <div className="row">
                        <div className="col-xl-12">
                            <div className="project-details__pagination-box">
                                <ul className="project-details__pagination list-unstyled clearfix">
                                    <li className="previous">
                                        <div className="icon">
                                            <button
                                                onClick={() => handlePageChange(currentPage - 1)}
                                                disabled={currentPage === 1}
                                                aria-label="Previous"
                                            >
                                                <i className="lnr lnr-icon-arrow-left"></i>
                                            </button>
                                        </div>
                                    </li>
                                    <li className="count px-3">
                                        <span>Page {currentPage} of {lastPage}</span>
                                    </li>
                                    <li className="next">
                                        <div className="icon">
                                            <button
                                                onClick={() => handlePageChange(currentPage + 1)}
                                                disabled={currentPage === lastPage}
                                                aria-label="Next"
                                            >
                                                <i className="lnr lnr-icon-arrow-right"></i>
                                            </button>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>

                </div>
            </section>
        </>
    );
};

export default ProductCategories;
