import React, { useState, useEffect } from "react";
import Sidenav from "./modules/Sidenav";
import { Link, useNavigate } from "react-router-dom";
import api from '../../api';
import { toast } from 'react-toastify';

const Wishlists = ({ wishlistItem = [], handleCart, handleWishlistRemove }) => {
  const navigate = useNavigate();
  const [error, setError] = useState(null);
  const [loginData, setLoginData] = useState(null);
  const [wishlists, setWishlists] = useState(wishlistItem);

  useEffect(() => {
    const userData = sessionStorage.getItem('user_data');
    if (userData) {
      try {
        const parsedData = JSON.parse(userData);
        setLoginData(parsedData);
      } catch (err) {
        setLoginData(null);
        navigate("/login");
      }
    } else {
      navigate("/login");
    }
  }, [navigate]);

  useEffect(() => {
    setWishlists(wishlistItem);
  }, [wishlistItem]);

  const handleRemove = async (slug) => {
    try {
      await handleWishlistRemove(slug);
      setWishlists(prevWishlists => prevWishlists.filter(item => item.product.slug !== slug)); // Remove from local state
      // toast.success("Item removed from wishlist.");
    } catch (err) {
      toast.error("Failed to remove item from wishlist.");
    }
  };
console.log(wishlists);
  if (error) return <div>{error}</div>;

  return (
    <section className="dashboard">
      <div className="container-fluid">
        <Sidenav />
        <div className="aiz-user-panel">
          <div className="card shadow-none rounded-0 border">
            <div className="card-header border-bottom-0 bg-transparent">
              <h5 className="mb-0 fs-20 fw-700 text-dark">Wishlist</h5>
            </div>
            <div className="card-body">
              {wishlists.length > 0 ? (
                <div className="row">
                  {wishlists.map((product) => (
                    <div className="product-block col-lg-4 col-sm-6" key={product.id}>
                      <div className="inner-box">
                        <div className="image">
                          <Link to={`/products/${product.product.slug}`}>
                            <img
                              src={product.product.thumbnail_image}
                              alt={product.product.name}
                            />
                          </Link>
                        </div>
                        <div className="content">
                          <h4>
                            <Link to={`/products/${product.product.slug}`}>
                              {product.product.name}
                            </Link>
                          </h4>
                          <span className="price">{product.product.base_price}</span>
                        </div>
                        <div className="icon-box">
                          <button
                            className="ui-btn like-btn"
                            onClick={() => handleRemove(product.product.slug)}
                          >
                            <i className="fa fa-trash"></i>
                          </button>
                          {loginData ? (
                            <button
                              onClick={() => handleCart(product.product.id, '', loginData.id, 1)}
                              className="ui-btn add-to-cart"
                            >
                              <i className="fa fa-shopping-cart"></i>
                            </button>
                          ) : (
                            <Link to="/login" className="ui-btn add-to-cart">
                              <i className="fa fa-shopping-cart"></i>
                            </Link>
                          )}
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              ) : (
                <div className="text-center bg-white p-4">
                  <img
                    className="w-50 h-200px"
                    src="/images/nothing.svg"
                    alt="No Wishlist"
                  />
                  <h5 className="mb-0 h5 mt-3">There isn't anything added yet</h5>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Wishlists;