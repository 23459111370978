import React from 'react';
import { Helmet } from "react-helmet";
import { Link } from 'react-router-dom';

const ShippingPolicy = () => {

  return (
    <>
      <Helmet>
        <title>Shipping Policy | Niya Living</title>
        <meta name="title" content="Shipping Policy | Niya Living" />
        <meta name="description" content="Learn about Niya Living's shipping policy. Find information on delivery times, shipping charges, and order tracking to ensure a smooth shopping experience." />
      </Helmet>
      <div className="auto-container py-5 text-justify">
        <h2>Shipping Policy</h2>
        <p>Thank you for choosing <Link to="/"><b>Niya Living</b></Link>. We offer reliable shipping options to ensure that your orders reach you in perfect condition.</p>

        <h4>Shipping Methods</h4>
        <ul>
          <li><strong>Standard Shipping:</strong> Estimated delivery within 5-10 business days.</li>
          <li><strong>Express Shipping:</strong> Estimated delivery within 2-3 business days for an additional fee.</li>
        </ul>

        <h4>Free Shipping</h4>
        <p>We offer free standard shipping on orders over a certain amount. Check our website for the current threshold.</p>

        <h4>Order Processing Time</h4>
        <p>All orders are processed within 1-2 business days. Please note that this does not include weekends or holidays.</p>

        <h4>Shipping Restrictions</h4>
        <ul>
          <li>We currently ship to addresses within India. For international shipping inquiries, please contact us at <a className="text-dark" href='mailto:care@niyaliving.in'>care@niyaliving.in</a>.</li>
          <li>We do not ship to P.O. Boxes or APO/FPO addresses.</li>
        </ul>

        <h4>Delivery Confirmation</h4>
        <p>Once your order has shipped, you will receive a confirmation email with a tracking number. You can use this number to track your order's progress.</p>

        <h4>Damaged or Lost Packages</h4>
        <p>If your package is damaged or lost in transit, please contact us at <a className="text-dark" href='mailto:care@niyaliving.in'>care@niyaliving.in</a>. We will assist you in filing a claim with the shipping carrier.</p>

        <h4>Contact Us</h4>
        <p>For any questions regarding our shipping policy, please reach out to us at <a className="text-dark" href='mailto:care@niyaliving.in'>care@niyaliving.in</a>.</p>
      </div>
    </>
  );
};

export default ShippingPolicy;
