import React, { useEffect, useState } from 'react';
import FooterServices from '../../components/FooterServices';
import { Helmet } from "react-helmet";
import BannerSection from "./modules/BannerSection";
import ProductSection from "./modules/ProductSection";
import ShopCategory from "./modules/ShopCategory";
import ClientsSection from "./modules/ClientsSection";
import ProjectSection from "./modules/ProjectSection";
import StoriesSection from "./modules/StoriesSection";
import BlogSection from "./modules/BlogSection";
import VideoSection from "./modules/VideoSection";
import ProductBanner from "./modules/ProductBanner";
import api from '../../api';

export default function Home({ handleCart, handleWishlist }) {
    const [settings, setSettings] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [metaData, setMetaData] = useState({});
    const [activeIndex, setActiveIndex] = useState(0);

    const toggleAccordion = (index) => {
        setActiveIndex(activeIndex === index ? null : index);
    };

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await api.get('/business-settings');
                setSettings(response.data.data.data);
            } catch (err) {
                setError(err);
            } finally {
                setLoading(false);
            }
        };

        fetchData();
    }, []);

    useEffect(() => {
        if (settings.length > 0) {
            const meta = {};
            settings.forEach(setting => {
                if (setting.type === "header_logo_path") {
                    meta.header_logo_path = setting.value;
                }
                if (setting.type === 'meta_title') {
                    meta.meta_title = setting.value;
                }
                if (setting.type === 'meta_keywords') {
                    meta.meta_keywords = setting.value;
                }
                if (setting.type === 'meta_description') {
                    meta.meta_description = setting.value;
                }
                if (setting.type === 'website_name') {
                    meta.website_name = setting.value;
                }
            });
            setMetaData(meta);
        }
    }, [settings]);

    if (error) return <div>Error: {error.message}</div>;

    return (
        <>
            <Helmet>
                <title>{metaData.meta_title}</title>
                {/* <meta name="keywords" content={metaData.meta_keywords} /> */}
                <meta name="title" content={metaData.meta_title} />
                <meta name="description" content={metaData.meta_description} />

                {/* Open Graph meta tags */}
                <meta property="og:title" content={metaData.meta_title} />
                <meta property="og:site_name" content={metaData.website_name} />
                <meta property="og:url" content={window.location.href} />
                <meta property="og:description" content={metaData.meta_description} />
                <meta property="og:type" content="website" />
                <meta property="og:image" content={metaData.header_logo_path} />

                {/* Twitter Card meta tags */}
                <meta name="twitter:card" content="summary_large_image" />
                <meta name="twitter:site" content="@niyaiving" />
                <meta name="twitter:title" content={metaData.meta_title} />
                <meta name="twitter:description" content={metaData.meta_description} />
                <meta name="twitter:image" content={metaData.header_logo_path} />
            </Helmet>
            <BannerSection />
            <ShopCategory />
            <ProductBanner />
            <ProductSection handleCart={handleCart} handleWishlist={handleWishlist} />
            {/* <VideoSection /> */}
            {/* <ClientsSection /> */}
            <ProjectSection />
            <StoriesSection />
            <BlogSection />

            {/* faq section */}
            <section className="faqs-section-home1 my-1 pb-md-20">
                <div className="auto-container">                    
                    <div className="row">
                    <div className="text-center">
                        <h3 className="sec-title">Faqs</h3>
                    </div>
                        <div className="faq-column col-lg-12">
                            <div className="inner-column">
                                <ul className="accordion-box wow fadeInLeft">
                                    {[
                                        { question: "What is Niya Living?", answer: "Niya Living is a furniture and home decor brand that combines creativity, craftsmanship, and innovation to design unique home pieces." },
                                        { question: "What type of furniture does Niya Living offer?", answer: "Niya Living offers a variety of home furniture and decor items, all crafted with quality materials and designs." },
                                        { question: "How can I place an order?", answer: "Orders can be placed directly through the website by selecting your preferred items and proceeding to checkout." },
                                        { question: "What payment methods are accepted?", answer: "Most major payment options, including credit/debit cards and UPI, are likely accepted." },
                                        { question: "What types of wood are used in Niya Living furniture?", answer: "Niya Living uses high-quality wood such as Acacia, sheesham, mango, and engineered wood for durability and aesthetics." },
                                        { question: "Is solid wood furniture better than engineered wood?", answer: "Solid wood furniture is more durable and long-lasting, while engineered wood is cost-effective and resistant to moisture." },
                                        { question: "How should I clean my wooden furniture?", answer: "Use a soft, damp cloth for dusting and avoid harsh chemicals or excessive water." },
                                        { question: "Is wooden furniture eco-friendly?", answer: "Sustainable wood sources and recycled materials make wooden furniture an eco-friendly choice." },
                                        { question: "Does Niya Living provide a warranty on wooden furniture?", answer: "Warranty policies depend on the product. Check with Niya Living’s customer support for specific details." },
                                        { question: "Are wooden beds durable?", answer: "Yes, solid wood beds last for decades and provide strong support compared to metal or engineered wood beds." },
                                    ].map((faq, index) => (
                                        <li
                                            key={index}
                                            className={`accordion block ${activeIndex === index ? 'active-block' : ''}`}
                                        >
                                            <div
                                                className={`acc-btn ${activeIndex === index ? 'active' : ''}`}
                                                onClick={() => toggleAccordion(index)}
                                            >
                                                {faq.question}
                                                <div className="arrow far fa-arrow-right"></div>
                                            </div>
                                            <div className={`acc-content ${activeIndex === index ? 'current' : ''}`}>
                                                <div className="content">
                                                    <div className="text">{faq.answer}</div>
                                                </div>
                                            </div>
                                        </li>
                                    ))}
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            

            <FooterServices />
        </>
    );
}
