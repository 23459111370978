import React from 'react';

const AboutPiece = ({ prdData }) => {
    if (!prdData?.about_heading) {
        return null;
    }

    return (
        <section className="about-piece-section">
            <div className="container-fluid">
                <div className="sec-title text-center">
                    <h2>{prdData.about_heading}</h2>
                </div>

                <div className="row">
                    <div className="col-lg-12 col-sm-12">
                        <p dangerouslySetInnerHTML={{ __html: prdData.about_description }}></p>
                        {prdData.about_image && (
                            <img src={prdData.about_image} alt={prdData.about_heading} />
                        )}
                    </div>
                </div>
            </div>
        </section>
    );
};

export default AboutPiece;
